import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 500.000000 500.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          
          <path d="M0 2500 l0 -2500 2500 0 2500 0 0 2500 0 2500 -2500 0 -2500 0 0
-2500z m3125 1361 c109 -41 110 -41 95 -52 -12 -8 -10 -9 7 -4 24 7 39 -21 28
-55 -4 -13 0 -23 14 -32 12 -7 21 -20 21 -28 0 -13 -1 -13 -11 0 -9 13 -10 13
-7 0 1 -8 -3 -25 -10 -38 -8 -13 -10 -32 -6 -45 7 -22 8 -22 14 3 6 24 7 24
11 -7 3 -19 1 -30 -4 -26 -6 4 -7 -9 -4 -33 7 -44 -7 -20 -18 31 -4 17 -29 52
-58 81 -47 47 -58 53 -109 59 -87 11 -188 -10 -282 -60 -359 -188 -696 -358
-701 -353 -8 7 351 288 530 416 167 119 262 159 380 161 37 1 80 -6 110 -18z
m-997 -1 c83 -9 280 -53 317 -70 11 -5 29 -11 40 -13 17 -4 16 -5 -4 -6 -20
-1 -21 -3 -11 -16 11 -13 8 -15 -16 -16 -27 -2 -28 -2 -4 -6 14 -3 29 -9 34
-13 5 -5 0 -7 -12 -3 -11 2 -29 0 -39 -7 -15 -9 -13 -10 12 -5 l30 6 -27 -25
c-15 -15 -34 -26 -42 -26 -8 0 -63 12 -123 26 -149 35 -259 42 -317 20 -49
-19 -101 -70 -122 -122 -16 -39 -18 -130 -3 -194 5 -25 95 -215 199 -422 104
-207 183 -375 176 -372 -6 2 -19 17 -28 32 -10 15 -42 56 -73 91 -142 161
-354 450 -405 550 -56 111 -65 240 -25 346 54 145 144 218 300 244 28 5 52 9
55 10 3 0 42 -3 88 -9z m1167 -90 c3 -5 1 -10 -4 -10 -6 0 -11 5 -11 10 0 6 2
10 4 10 3 0 8 -4 11 -10z m-775 -20 c-14 -10 -27 -17 -29 -15 -8 8 22 35 38
35 12 0 10 -5 -9 -20z m792 -32 c-7 -7 -12 -8 -12 -2 0 6 3 14 7 17 3 4 9 5
12 2 2 -3 -1 -11 -7 -17z m5 -48 c-11 -18 -27 -27 -27 -15 0 13 25 43 32 39 4
-3 2 -14 -5 -24z m-40 -212 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1
-19z m-367 -120 c41 -51 77 -95 80 -98 7 -5 72 -89 191 -245 186 -245 229
-334 229 -476 0 -176 -105 -320 -268 -365 -80 -23 -128 -25 -192 -8 -30 8 -63
11 -75 8 -15 -5 -17 -3 -8 6 8 9 23 9 63 1 28 -6 65 -10 83 -10 28 1 26 3 -13
10 -25 4 -67 11 -93 15 -32 4 -44 10 -35 15 10 7 9 9 -3 9 -9 0 -41 11 -70 24
-30 13 -70 27 -89 31 -19 4 -46 19 -59 34 -14 16 -21 20 -18 9 4 -12 2 -15 -9
-11 -19 7 -18 17 6 38 19 17 23 17 82 2 129 -34 303 -57 356 -47 69 12 145 77
162 139 24 87 2 230 -50 316 -54 91 -356 695 -347 695 2 0 36 -42 77 -92z
m359 13 c0 -43 4 -88 10 -99 8 -16 7 -21 -5 -26 -12 -4 -13 -10 -4 -26 6 -11
7 -20 1 -20 -5 0 -11 -6 -14 -13 -4 -10 2 -13 18 -9 23 4 24 3 13 -34 -13 -50
-22 -59 -13 -14 7 33 6 34 -9 15 -8 -11 -15 -24 -15 -30 0 -15 35 -55 48 -55
6 0 11 -7 11 -16 0 -14 -2 -15 -17 -3 -10 8 -27 25 -38 39 -11 14 -28 33 -37
42 -10 10 -18 20 -18 23 0 3 8 5 18 5 16 1 16 1 0 11 -13 7 -15 15 -9 32 5 12
15 48 22 80 8 37 15 53 21 45 4 -8 7 1 6 22 -3 49 1 110 7 110 3 0 4 -36 4
-79z m118 7 c-3 -7 -5 -2 -5 12 0 14 2 19 5 13 2 -7 2 -19 0 -25z m-20 -100
c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z m-47 -121 c0 -13 -6 -32
-14 -43 -13 -17 -13 -14 -3 24 13 47 17 52 17 19z m-754 -13 c19 -9 42 -30 50
-46 13 -26 13 -30 -1 -44 -14 -15 -14 -17 1 -28 15 -12 15 -15 0 -48 -34 -71
-132 -80 -191 -18 -23 24 -28 38 -27 77 0 42 5 52 41 85 45 42 74 47 127 22z
m-697 -286 c-26 -89 -59 -270 -59 -326 0 -38 6 -76 16 -95 20 -40 71 -95 104
-112 74 -38 227 -21 335 39 101 57 686 349 689 345 8 -9 -488 -398 -599 -470
-153 -99 -305 -127 -439 -80 -209 73 -295 262 -237 521 37 169 77 311 84 304
4 -4 7 -1 7 7 0 8 25 -12 55 -43 51 -54 53 -59 44 -90z m728 -574 c-3 -3 -12
-4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2 13 -5z m-40 -30 c-3 -3 -12 -4 -19 -1 -8
3 -5 6 6 6 11 1 17 -2 13 -5z m240 -60 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11
1 17 -2 13 -5z m-588 -445 c28 -60 51 -115 51 -120 0 -31 26 9 75 111 l54 115
58 0 58 0 0 -205 0 -205 -47 -3 -47 -3 -3 133 -3 133 -49 -112 c-48 -110 -50
-113 -81 -113 -31 0 -34 4 -83 108 l-50 107 -3 -105 c-1 -58 -1 -115 -1 -127
1 -21 -3 -23 -48 -23 l-50 0 0 210 0 210 59 0 58 0 52 -111z m491 26 c0 -47 4
-85 9 -85 5 0 24 19 44 43 19 23 52 61 74 85 39 42 40 43 98 40 l58 -3 -76
-83 c-45 -49 -74 -89 -70 -95 4 -7 40 -61 80 -120 40 -59 73 -110 73 -113 0
-3 -28 -4 -61 -2 l-62 3 -51 77 c-28 42 -55 77 -59 77 -4 1 -19 -11 -32 -26
-19 -22 -24 -39 -25 -81 l0 -53 -52 3 -53 3 -3 195 c-1 107 0 200 2 207 4 8
24 13 56 13 l50 0 0 -85z m-1452 -547 c-3 -7 -25 -14 -51 -16 -42 -3 -47 -6
-47 -28 0 -22 4 -24 46 -24 59 0 55 -24 -4 -28 -41 -3 -42 -4 -42 -38 0 -24
-5 -34 -15 -34 -12 0 -15 17 -15 90 l0 90 66 0 c48 0 65 -3 62 -12z m160 -75
c-3 -74 -6 -88 -20 -91 -16 -3 -18 6 -18 87 0 85 1 91 20 91 20 0 21 -5 18
-87z m223 27 l44 -61 3 61 c2 50 6 61 19 58 9 -2 16 -6 15 -10 -1 -5 -2 -44
-2 -88 0 -100 -11 -103 -74 -19 l-46 61 0 -61 c0 -54 -2 -61 -20 -61 -19 0
-20 7 -20 90 0 81 2 90 19 90 11 0 36 -24 62 -60z m259 -30 c0 -73 -3 -90 -15
-90 -12 0 -15 17 -15 90 0 73 3 90 15 90 12 0 15 -17 15 -90z m254 84 c9 -3
16 -12 16 -19 0 -10 -13 -13 -42 -12 -58 3 -58 -21 0 -41 31 -10 44 -20 49
-40 11 -47 -64 -81 -118 -52 -25 14 -26 45 -1 34 31 -14 76 -10 80 7 2 11 -10
20 -38 29 -48 16 -66 41 -50 69 5 11 15 22 22 24 19 8 64 8 82 1z m196 -34 l0
-40 45 0 45 0 0 40 c0 33 3 40 20 40 19 0 20 -7 20 -91 0 -81 -2 -90 -17 -87
-12 2 -19 14 -21 36 -3 31 -5 32 -48 32 -44 0 -44 0 -44 -36 0 -27 -4 -35 -16
-32 -13 2 -16 18 -15 86 0 45 0 84 1 87 0 3 7 5 15 5 11 0 15 -11 15 -40z
m408 28 c-3 -7 -27 -15 -54 -18 -65 -7 -69 -33 -5 -40 63 -7 66 -30 4 -30 -44
0 -48 -2 -49 -25 -2 -24 1 -25 52 -25 41 0 54 -4 54 -15 0 -12 -14 -15 -70
-15 l-70 0 0 90 0 90 71 0 c52 0 70 -3 67 -12z m258 4 c34 -22 37 -68 7 -103
-11 -14 -10 -20 8 -42 20 -26 20 -27 1 -27 -11 0 -27 11 -36 25 -23 35 -66 35
-66 -1 0 -19 -5 -25 -17 -22 -15 3 -18 16 -19 91 l-1 87 56 0 c31 0 61 -4 67
-8z m283 -1 c25 -16 2 -31 -40 -25 -57 9 -54 -20 6 -46 37 -16 45 -24 45 -46
0 -48 -57 -69 -115 -42 -29 13 -28 35 1 30 87 -16 106 7 34 41 -49 24 -62 53
-35 81 18 17 81 22 104 7z m181 -54 c0 -67 11 -87 45 -87 40 0 49 16 50 82 1
50 5 64 18 66 15 3 17 -6 17 -63 0 -57 -3 -69 -25 -90 -31 -32 -86 -34 -115
-5 -16 16 -20 33 -20 90 0 56 3 70 15 70 12 0 15 -14 15 -63z m398 51 c15 -15
16 -50 2 -64 -8 -8 -6 -15 5 -24 20 -16 19 -39 -2 -62 -12 -13 -31 -18 -75
-18 l-58 0 0 90 0 90 58 0 c32 0 63 -5 70 -12z"/>
<path d="M2420 3780 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2716 2299 c3 -5 0 -9 -7 -9 -7 0 -10 -2 -7 -5 3 -3 16 -2 28 3 l24
9 -22 5 c-12 4 -20 2 -16 -3z"/>
<path d="M3068 2153 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M3241 3260 c-1 -8 -4 -35 -6 -60 -4 -34 -2 -41 6 -27 5 9 8 36 6 60
-2 23 -5 35 -6 27z"/>
<path d="M2601 3064 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2920 1155 c0 -36 0 -36 38 -33 34 3 37 6 37 33 0 27 -3 30 -37 33
-38 3 -38 3 -38 -33z"/>
<path d="M3770 1170 c0 -18 5 -21 38 -18 25 2 37 8 37 18 0 10 -12 16 -37 18
-33 3 -38 0 -38 -18z"/>
<path d="M3770 1095 c0 -22 4 -25 33 -25 17 0 38 4 46 9 24 15 -6 41 -45 41
-30 0 -34 -3 -34 -25z"/>

        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
